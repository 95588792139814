#splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  background: #fff;
  .splash-screen-title {
    font-size: 64px;
    color: var(--ion-color-primary);
  }
  animation: fadeOut 3s ease-in-out 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
