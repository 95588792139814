#content-card {
  ion-card {
    display: flex;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 12px 16px rgba(69, 91, 99, 0.07);
    border-radius: 10px;
    align-items: center;
  }
  ion-card-header {
    padding-bottom: 8px;
  }
  ion-card-title {
    font-size: 16px;
    font-weight: bold;
  }
  ion-card-description {
    font-size: 12px;
  }
  ion-thumbnail {
    margin-left: 16px;
  }
  .right-icon {
    position: absolute;
    top: 0;
    width: 100%;
    border-top: 3px solid #e8e8e8;
    ion-icon {
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 24px;
    }
    &.completed {
      border-color: var(--ion-color-primary);
    }
  }
  ion-thumbnail {
    ion-icon {
      margin-left: 20px;
      font-size: 28px;
    }
  }
  .vimeo-thumbnail {
    width: 85px;
    height: 50px;
    margin-right: 12px;
  }
  .thumbnail {
    width: 100%;
    ion-card-header {
      display: flex;
      align-items: center;
    }
    ion-card-title {
      margin-top: 0;
    }
  }
}
