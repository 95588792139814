#progress-card {
  .progress-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #d7eced;
  }
  ion-card {
    position: relative;
    --background: #fffcf9;
  }
  ion-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
}
