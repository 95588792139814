#custom-carousel {
  .slick-arrow:before {
    font-size: 34px;
    color: black;
  
  }
  .slick-next:before {
    font-size: 34px;
    color: black;
  
  }
  
  .slick-prev {
    left:12px;
    z-index: 1000;
  }
  .slick-next {
    right:40px;
    z-index: 1000;
  }
  
  .slick-slider{
    width: 100%;
  }
}

