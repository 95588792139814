#root-page {
  ion-img {
    height: 250px;
  }
  h1 {
    font-size: 36px;
  }
  p {
    font-size: 21px;
  }
  ion-content::part(background) {
    background-color: #ffffff;
  }
}
