#div-contact-card {
  width: 100%;
}
#theCard {
  width: 100%;
  margin-left: 0px;
}

#popupDetails {
  height: 0px;
}
#contactIcons {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 0.5px solid lightgrey;
}
#nameAndTitle {
  display: flex;
  height: 70px;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 0.5px solid lightgrey;
  flex-direction: column;
  cursor: pointer;
}
#cardAvatar {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.myName {
  font-family: Quicksand;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.myTitle {
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
#thePhone,
#theEmail {
  width: 30px;
  height: 30px;
  font-size: 24px;
  float: right;
  margin-left: 20px;
  background-color: primary;
  cursor: pointer;
}
