@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap);
#terms{align-items:flex-end;--height: 100%;--width: 100%;--padding-top: 15px}#terms ion-content{--padding-bottom: 15px;--padding-start: 15px;--padding-top: 15px;--padding-end: 15px}#terms ion-title{text-align:center}#terms ion-icon{margin-left:5px;font-size:25px}#terms ion-label strong{display:block;font-size:24px;margin-bottom:10px}.term1{line-height:25px;letter-spacing:1px}.terms-list{display:flex;cursor:pointer}

.custom-modal{--max-width: 340px;--max-height: 400px}.custom-modal .ion-page{align-items:center;justify-content:center}.custom-modal ion-img,.custom-modal amplify-s3-image{max-width:200px;--height: auto}

#award-card ion-card{position:relative;--background: transparent}#award-card ion-card-header,#award-card ion-card-content{padding:0}#award-card ion-card-title{margin:8px 0;font-size:14px;font-weight:700}#award-card ion-card-content p{font-size:12px}#award-card amplify-s3-image{--width: 100%}#award-card .card-lock{display:flex;justify-content:center;padding-top:50px;position:absolute;top:0;width:100%;bottom:0;background-color:rgba(255,255,255,0.68);z-index:1}

#progress-card .progress-overlay{position:absolute;top:0;bottom:0;background-color:#d7eced}#progress-card ion-card{position:relative;--background: #fffcf9}#progress-card ion-card-content{display:flex;align-items:center;justify-content:space-between}#progress-card h3{font-size:16px;font-weight:700}

#awards-page canvas{display:none}#awards-page #certificate{display:none}.container{display:inline-block;position:relative}.container img{display:block}.positioner{width:50%;height:50%;position:absolute;left:0;top:0}.icon{position:absolute;right:-20px;bottom:-20px;width:32px;height:32px;border-radius:20px}



#custom-carousel .slick-arrow:before{font-size:34px;color:black}#custom-carousel .slick-next:before{font-size:34px;color:black}#custom-carousel .slick-prev{left:12px;z-index:1000}#custom-carousel .slick-next{right:40px;z-index:1000}#custom-carousel .slick-slider{width:100%}


#explore-card{margin:0 auto}#explore-card ion-card{position:relative;display:flex;min-height:136px;align-items:flex-end;justify-content:center}#explore-card ion-card::after{content:'';position:absolute;top:0;bottom:0;width:100%;border-radius:0px 0px 10px 10px}#explore-card ion-card-title{width:100%;padding:8px;font-size:14px;font-weight:700;--color: "black";z-index:1}#explore-card .vimeo-thumbnail{position:absolute;width:100%;height:100%}#explore-card .vimeo-thumbnail ion-img{height:100%;object-fit:cover}#explore-card amplify-s3-image{position:absolute;height:100%;--width: 100%}#explore-card ion-icon{position:absolute;top:5px;right:5px;font-size:20px;color:#fff;z-index:1}


#custom-card ion-card{padding:20px 15px;display:flex;align-items:center;border-radius:10px;justify-content:flex-start;border:1px solid #e8e7e7;margin-bottom:0}#custom-card ion-card-title{font-size:16px;font-weight:700;margin-bottom:5px}#custom-card ion-card-subtitle{font-size:14px;color:var(--ion-text-color)}#custom-card .main-icon{display:flex;align-items:center;justify-content:center;width:60px;height:60px;border-radius:16px}#custom-card .main-icon:not(.image){border:2px dashed var(--ion-color-primary)}#custom-card .title-subtitle{margin-left:16px}#custom-card .caret-icon{margin-left:auto}

#help-page ion-card-content{padding:0}

#awards-carousel h2{padding:0;margin:0;font-weight:700;font-size:18px}


#playlist-card ion-card{margin:0}#playlist-card ion-card-header{padding-left:12px;padding-right:12px;padding-top:0;padding-bottom:0}#playlist-card ion-card-title{font-size:14px;font-weight:700}#playlist-card ion-card-content{padding-left:12px;padding-right:12px;width:100%;display:flex;justify-content:space-between;align-items:flex-end;padding-bottom:12px;font-size:10px;font-weight:700}#playlist-card amplify-s3-image{--width: 100%}#playlist-card ion-icon{font-size:24px}

#playlist-grid .playlist-cards ion-col{--ion-grid-column-padding: 8px}#playlist-grid h2{padding:0;margin:0;font-weight:700;font-size:18px}

.terms-modal{align-items:flex-end;--height: 100%;--width: 100%}.terms-modal ion-content{--padding-bottom: 15px;--padding-start: 15px;--padding-top: 15px;--padding-end: 15px
    }.terms-modal ion-title{text-align:center}.terms-modal ion-icon{margin-left:5px;font-size:25px}.terms-modal ion-label strong{display:block;font-size:24px;margin-bottom:10px}.terms-list{display:flex}

#home-page ion-toolbar{--min-height: 70px;--border-width: 0}#home-page ion-title{font-size:18px;font-weight:700}#home-page ion-title ion-img{margin-right:12px;width:48px;height:48px}#home-page .title-container{display:flex;align-items:center;justify-content:flex-start}.ios #home-page ion-title{padding-inline:16px}



#accordion ion-card{margin:0;--color: #fff;border-radius:6px;padding:18px 16px;display:flex;align-items:center;justify-content:space-between;--background: var(--ion-color-secondary)}#accordion ion-card-title{color:#fff;font-size:16px;font-weight:700}


#profile-page .custom-avatar-image{max-width:120px;border-radius:100%;overflow:hidden;margin:0 auto}

.parent{display:flex;align-items:center;justify-content:center;height:30%;font-size:24px;padding-left:10px}

ion-select{width:100%;font-family:Quicksand;font-style:normal;font-weight:bold;font-size:16px;line-height:20px;color:#363e54}ion-select::part(icon){opacity:1;color:#363e54}ion-label{color:#363e54;font-size:14px;line-height:17px;font-weight:500;font-style:normal}#itemLanguage{width:100%}

#content-card ion-card{display:flex;border:1px solid #e8e8e8;box-shadow:0px 12px 16px rgba(69,91,99,0.07);border-radius:10px;align-items:center}#content-card ion-card-header{padding-bottom:8px}#content-card ion-card-title{font-size:16px;font-weight:bold}#content-card ion-card-description{font-size:12px}#content-card ion-thumbnail{margin-left:16px}#content-card .right-icon{position:absolute;top:0;width:100%;border-top:3px solid #e8e8e8}#content-card .right-icon ion-icon{position:absolute;right:6px;top:6px;font-size:24px}#content-card .right-icon.completed{border-color:var(--ion-color-primary)}#content-card ion-thumbnail ion-icon{margin-left:20px;font-size:28px}#content-card .vimeo-thumbnail{width:85px;height:50px;margin-right:12px}#content-card .thumbnail{width:100%}#content-card .thumbnail ion-card-header{display:flex;align-items:center}#content-card .thumbnail ion-card-title{margin-top:0}

#playlist-page .react-tabs__tab-list{list-style:none;padding:0 16px;display:flex;margin:0;justify-content:space-around}#playlist-page .react-tabs__tab-list li{padding:16px}#playlist-page .react-tabs__tab-list .react-tabs__tab--selected{font-weight:700;border-bottom:3px solid}#playlist-page h4{font-size:18px;font-weight:700;margin:20px 10px}

#question-container{margin-top:40px}#question-container h2{font-weight:bold;font-size:18px}#question-container ol{padding-left:20px}#question-container ion-card{overflow:visible}#question-container ion-card-content{display:flex;padding-top:0;align-items:center}#question-container ion-radio{position:absolute;width:100%;height:100%;--color: transparent;--color-checked: transparent}#question-container .question-count{position:relative;top:-20px}#question-container .question-count ion-button{opacity:1;--box-shadow: none}#question-container ion-radio-group ion-button{margin:16px 10px;white-space:normal;height:60px}#question-container a{display:block;text-align:center;margin:16px}



.contact-modal{align-items:flex-end;--height: 80%;--border-radius: 20px 20px 0 0}.contact-modal ion-grid{display:flex;flex-direction:column;justify-content:space-between}.contact-modal ion-avatar{height:50px;width:50px;overflow:hidden}.contact-modal ion-label strong{display:block;font-size:24px;margin-bottom:10px}.contact-modal ion-label span{font-size:20px}.contact-modal ion-item{padding-bottom:0;--inner-padding-end: 0}.name{line-height:1.25}

#contact-item ion-avatar{height:50px;width:50px;overflow:hidden}#contact-item ion-label strong{display:block;margin-bottom:4px}#contact-item ion-label span{font-size:14px}#contact-item ion-item{--inner-padding-end: 0}#contact-item ion-item::part(native){padding-left:0}#contact-item a{font-size:22px;margin-left:18px}

#div-contact-card{width:100%}#theCard{width:100%;margin-left:0px}#popupDetails{height:0px}#contactIcons{display:flex;height:70px;align-items:center;justify-content:flex-end;border-bottom:0.5px solid lightgrey}#nameAndTitle{display:flex;height:70px;align-items:flex-start;justify-content:center;border-bottom:0.5px solid lightgrey;flex-direction:column;cursor:pointer}#cardAvatar{width:70px;height:70px;cursor:pointer}.myName{font-family:Quicksand;font-size:16px;font-style:normal;font-weight:700;line-height:22px;letter-spacing:0em;text-align:left}.myTitle{font-family:Quicksand;font-size:14px;font-style:normal;font-weight:500;line-height:22px;letter-spacing:0em;text-align:left}#thePhone,#theEmail{width:30px;height:30px;font-size:24px;float:right;margin-left:20px;background-color:primary;cursor:pointer}


#confirmation-page .confirmation-code{font-size:30px;letter-spacing:30px}


#login-page .link-secondary{color:var(--ion-secondary-color)}

#register-page ion-text p{color:var(--ion-color-primary);padding-left:15px}

#splash-screen{display:flex;align-items:center;justify-content:center;position:absolute;top:0;width:100%;bottom:0;background:#fff;-webkit-animation:fadeOut 3s ease-in-out 1s;animation:fadeOut 3s ease-in-out 1s}#splash-screen .splash-screen-title{font-size:64px;color:var(--ion-color-primary)}@-webkit-keyframes fadeOut{from{opacity:1}to{opacity:0}}@keyframes fadeOut{from{opacity:1}to{opacity:0}}

#root-page ion-img{height:250px}#root-page h1{font-size:36px}#root-page p{font-size:21px}#root-page ion-content::part(background){background-color:#ffffff}


#welcome-page ion-tab-bar{display:none}#welcome-page amplify-s3-image{--width: 136px;--height: 142px;margin:0 auto}#welcome-page ion-button{font-size:13px}#welcome-page h1{font-size:32px}#welcome-page ion-img{margin:40px 0}#welcome-page .blueseat-gif{width:250px;height:initial;display:inline-block}

a:focus{outline:none}ion-content{--padding-bottom: 5px;--padding-end: 5px;--padding-start: 5px;--padding-top: 5px}ion-content::part(background){background-color:#f7f7f7}ion-tab-bar{height:78px}ion-card{box-shadow:none}h1{font-size:24px;font-weight:700}hr{background-color:#e8e8e8}ion-button{text-transform:none}amplify-s3-image{--width: 100%}.auth-pages-text p{font-size:18px}.custom-button-margin-top{margin-top:60px}.icon-close{position:absolute;top:10px;right:10px;z-index:2}.ios ion-item{--padding-start: 0}.ios ion-card{margin:10px}.ios ion-card-subtitle{text-transform:none;font-weight:normal}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** Custom vars **/
  --ion-font-family: 'Quicksand';

  --ion-background-color: #f7f7f7;
  --ion-background-color-rgb: 247, 247, 247;

  --ion-text-color: #363e54;
  --ion-card-color: #363e54;
  --ion-text-color-rgb: 54, 62, 84;

  --ion-color-primary: #f45d48;
  --ion-color-primary-rgb: 244, 93, 72;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #d7523f;
  --ion-color-primary-tint: #f56d5a;

  --ion-color-secondary: #363e54;
  --ion-color-secondary-rgb: 54, 62, 84;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #30374a;
  --ion-color-secondary-tint: #4a5165;

  --ion-tab-bar-background: #ffffff;
  --ion-tab-bar-border-color: transparent;
  --ion-tab-bar-color: #363e54;

  --ion-toolbar-background: #ffffff;
  --ion-toolbar-border-color: transparent;

  --ion-card-background: #ffffff;

  /** primary **/
  /* --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff; */

  /** secondary **/
  /* --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff; */

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
  * Dark Colors
  * -------------------------------------------
  */

/* @media (prefers-color-scheme: dark) {

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
} */

