#accordion {
  ion-card {
    margin: 0;
    --color: #fff;
    border-radius: 6px;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    --background: var(--ion-color-secondary);
  }
  ion-card-title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }
}
