.custom-modal {
  --max-width: 340px;
  --max-height: 400px;
  .ion-page {
    align-items: center;
    justify-content: center;
  }
  ion-img,
  amplify-s3-image {
    max-width: 200px;
    --height: auto;
  }
}
