#explore-card {
  margin: 0 auto;
  ion-card {
    position: relative;
    display: flex;
    min-height: 136px;
    align-items: flex-end;
    justify-content: center;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
     
      border-radius: 0px 0px 10px 10px;
    }
  }
  ion-card-title {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    --color: "black";
    z-index: 1;
  }
  .vimeo-thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    ion-img {
      height: 100%;
      object-fit: cover;
    }
  }
  amplify-s3-image {
    position: absolute;
    height: 100%;
    --width: 100%;
  }
  ion-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: #fff;
    z-index: 1;
  }
}
