#welcome-page {
  ion-tab-bar {
    display: none;
  }
  amplify-s3-image {
    --width: 136px;
    --height: 142px;
    margin: 0 auto;
  }
  ion-button {
    font-size: 13px;
  }
  h1 {
    font-size: 32px;
  }
  ion-img {
    margin: 40px 0;
  }
  .blueseat-gif {
    width: 250px;
    height: initial;
    display: inline-block;
  }
}
