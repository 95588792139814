#award-card {
  ion-card {
    position: relative;
    --background: transparent;
  }
  ion-card-header,
  ion-card-content {
    padding: 0;
  }
  ion-card-title {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 700;
  }
  ion-card-content {
    p {
      font-size: 12px;
    }
  }
  amplify-s3-image {
    --width: 100%;
  }
  .card-lock {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.68);
    z-index: 1;
  }
}
