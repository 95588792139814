#question-container {
  margin-top: 40px;
  h2 {
    font-weight: bold;
    font-size: 18px;
  }
  ol{
    padding-left: 20px;
  }
  ion-card {
    overflow: visible;
  }
  ion-card-content {
    display: flex;
    padding-top: 0;
    align-items: center;
  }
  ion-radio {
    position: absolute;
    width: 100%;
    height: 100%;
    --color: transparent;
    --color-checked: transparent;
  }
  .question-count {
    position: relative;
    top: -20px;
    ion-button {
      opacity: 1;
      --box-shadow: none;
    }
  }
  ion-radio-group {
    ion-button {
      margin: 16px 10px;
      white-space: normal;
      height: 60px;
    }
  }
  a {
    display: block;
    text-align: center;
    margin: 16px;
  }
}
