#home-page {
  ion-toolbar {
    --min-height: 70px;
    --border-width: 0;
  }
  ion-title {
    font-size: 18px;
    font-weight: 700;
    ion-img {
      margin-right: 12px;
      width: 48px;
      height: 48px;
    }
  }
  .title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.ios {
  #home-page {
    ion-title {
      padding-inline: 16px;
    }
  }
}
