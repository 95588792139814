#playlist-grid {
  .playlist-cards {
    ion-col {
      --ion-grid-column-padding: 8px;
    }
  }
  h2 {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
  }
}
