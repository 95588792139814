#awards-page {
  canvas {
    display: none;
  }
  #certificate {
    display: none;
  }
}
.container {
  display: inline-block;
  position: relative;
}

.container img {
  display: block;
  
}

.positioner {
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0;
  top: 0;
 
}

.icon {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
}
