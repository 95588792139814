#contact-item {
  ion-avatar {
    height: 50px;
    width: 50px;
    overflow: hidden;
  }
  ion-label {
    strong {
      display: block;
      margin-bottom: 4px;
    }
    span {
      font-size: 14px;
    }
  }
  ion-item {
    --inner-padding-end: 0;
  }
  ion-item::part(native) {
    padding-left: 0;
  }
  a {
    font-size: 22px;
    margin-left: 18px;
  }
}
