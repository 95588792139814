.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  font-size: 24px;
  padding-left: 10px;
 
  
}
