#playlist-page {
  .react-tabs__tab-list {
    list-style: none;
    padding: 0 16px;
    display: flex;
    margin: 0;
    justify-content: space-around;
    li {
      padding: 16px;
    }
    .react-tabs__tab--selected {
      font-weight: 700;
      border-bottom: 3px solid;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 20px 10px;
  }
}
