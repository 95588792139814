#playlist-card {
  ion-card {
    margin: 0;
  }
  ion-card-header {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 0;
    padding-bottom: 0;
  }
  ion-card-title {
    font-size: 14px;
    font-weight: 700;
  }
  ion-card-content {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 12px;
    font-size: 10px;
    font-weight: 700;
  }
  amplify-s3-image {
    --width: 100%;
  }
  ion-icon {
    font-size: 24px;
  }
}
