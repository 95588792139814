.contact-modal {
  align-items: flex-end;
  --height: 80%;
  --border-radius: 20px 20px 0 0;
  ion-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ion-avatar {
    height: 50px;
    width: 50px;
    overflow: hidden;
  }
  ion-label {
    strong {
      display: block;
      font-size: 24px;
      margin-bottom: 10px;
    }
    span {
      font-size: 20px;
    }
  }
  ion-item {
    padding-bottom: 0;
    --inner-padding-end: 0;
  }
}
.name{
  line-height: 1.25
}