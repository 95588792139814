@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');

a:focus {
  outline: none;
}

ion-content {
  --padding-bottom: 5px;
  --padding-end: 5px;
  --padding-start: 5px;
  --padding-top: 5px;
  &::part(background) {
    background-color: #f7f7f7;
  }
}

ion-tab-bar {
  height: 78px;
}

ion-card {
  box-shadow: none;
}

h1 {
  font-size: 24px;
  font-weight: 700;
}

hr {
  background-color: #e8e8e8;
}

ion-button {
  text-transform: none;
}

amplify-s3-image {
  --width: 100%;
}

.auth-pages-text {
  p {
    font-size: 18px;
  }
}

.custom-button-margin-top {
  margin-top: 60px;
}

.icon-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.ios {
  ion-item {
    --padding-start: 0;
  }
  ion-card {
    margin: 10px;
  }
  ion-card-subtitle {
    text-transform: none;
    font-weight: normal;
  }
}
