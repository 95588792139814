#terms {
  align-items: flex-end;
  --height: 100%;
  --width: 100%;
  --padding-top: 15px;
  ion-content {
    --padding-bottom: 15px;
    --padding-start: 15px;
    --padding-top: 15px;
    --padding-end: 15px;
  }
  ion-title {
    text-align: center;
  }
  ion-icon {
    margin-left: 5px;
    font-size: 25px;
  }
  ion-label {
    strong {
      display: block;
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}
.term1 {
  line-height: 25px;
  letter-spacing: 1px;
}
.terms-list {
  display: flex;
  cursor: pointer;
}
