ion-select {
  width: 100%;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #363e54;
}
ion-select::part(icon) {
  opacity: 1;
  color: #363e54;
}

ion-label {
  color: #363e54;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-style: normal;
}
#itemLanguage {
  width: 100%;
}
