#custom-card {
  ion-card {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: flex-start;
    border: 1px solid #e8e7e7;
    margin-bottom: 0;
  }
  ion-card-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  ion-card-subtitle {
    font-size: 14px;
    color: var(--ion-text-color);
  }
  .main-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 16px;
    &:not(.image) {
      border: 2px dashed var(--ion-color-primary);
    }
  }
  .title-subtitle {
    margin-left: 16px;
  }
  .caret-icon {
    margin-left: auto;
  }
}
